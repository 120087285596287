'use strict';

let ReserveAndGo = {};

const cookie = require('js-cookie');
const googleHelper = require('../../js/components/googleHelper');
const appendToUrl = require('../util/appendToUrl');
const notification = require('../util/notification');
const cookieOptions = {
    secure: true,
    path: '/',
    expires: 1825
};
let lastSelectedMethod;

/**
 * On change handler for hiding RG popup
 *
 */
ReserveAndGo.onChangeHideRGPopup = () => {
    $(document).on('change', '.js-hide-rg-popup', function () {
        const hideRGPopup = $(this).is(':checked');
        cookie.set('rg_hide_popup', hideRGPopup, cookieOptions);
    });
};

/**
 * On click reserve and go
 *
 */
ReserveAndGo.onClickReserveAndGo = () => {
    $(document).on('click', '.js-button-rg', function () {
        if (!cookie.get('rg_hide_popup')) {
            $('#reserveAndGoModal').modal('show');
        } else {
            ReserveAndGo.switchDeliveryMethod(true);
        }
    });
};

/**
 * On switch delivery method
 *
 * @param {boolean} rgActive - reserve and go active?
 *
 */
ReserveAndGo.switchDeliveryMethod = (rgActive) => {
    var count = parseInt($('.minicart-quantity').text(), 10);
    if (cookie.get('rg_active') !== rgActive && count > 0) {
        $('#confirmSelectedDelivery').modal('show');
        $('.js-modal-content-online').toggleClass('d-none', rgActive);
        $('.js-modal-content-rg').toggleClass('d-none', !rgActive);
        $('#confirmSelectedDelivery').attr('data-rgActive', rgActive);
    } else {
        ReserveAndGo.setRGActive(rgActive);
    }
};

/**
 * On confirm delivery method
 *
 */
ReserveAndGo.onConfirmDeliveryMethod = () => {
    $(document).on('click', '.js-change-delivery-confirm', function () {
        const rgActive = $('#confirmSelectedDelivery').attr('data-rgActive') === 'true';
        ReserveAndGo.setRGActive(rgActive);
    });
};

/**
 * Enables/disable RG button
 * @param {boolean} rgActive - RG is active?
 *
 */
ReserveAndGo.setRGActive = (rgActive) => {
    const url = $('.js-reserve-and-go-switcher').attr('data-action');
    $.spinner().start();

    $.ajax({
        url: url,
        type: 'post',
        data: {
            rgActive: rgActive
        },
        success: function (res) {
            if (res.success) {
                $('.js-button-rg').prop('disabled', rgActive);
                $('.js-button-rg').toggleClass('active', rgActive);
                $('.js-button-delivery').prop('disabled', !rgActive);
                $('.js-button-delivery').toggleClass('active', !rgActive);

                $('.js-usp-conditions').toggleClass('d-none', rgActive);
                $('.js-usp-conditions-rg').toggleClass('d-none', !rgActive);

                const $averagePrice = $('.js-average-price');
                if ($averagePrice.length > 0) {
                    $('.js-average-price').toggleClass('d-none', !rgActive);
                    $('.js-main-price').toggleClass('d-none', rgActive);
                }

                $('html').attr('data-rg-active', rgActive);

                if ($('.page').data('action') === 'Cart-Show' && (res.selectedStore || !rgActive)) {
                    location.reload();
                }

                $('#confirmSelectedDelivery').modal('hide');

                if (!res.selectedStore && rgActive) {
                    $('#modalPreferredStore').modal('show');
                }

                // Trigger the change flow event
                $(document).trigger('shipping:updateFlow', rgActive ? 'reservation' : 'ecommerce');
            }
            $.spinner().stop();
        }
    });
};

/**
 * On click delivery button
 *
 */
ReserveAndGo.onClickPDPTab = () => {
    $(document).on('click', '.js-delivery-switch-button', function () {
        const rgActive = $(this).hasClass('switch-button-rg-active');
        if (($('html').attr('data-rg-active') === 'true') !== rgActive) {
            if (!cookie.get('rg_hide_popup') && rgActive) {
                $('#reserveAndGoModal').modal('show');
            } else {
                ReserveAndGo.switchDeliveryMethod(rgActive);
            }
        }
    });
};

/**
 * On click delivery button
 *
 */
ReserveAndGo.onClickDelivery = () => {
    $(document).on('click', '.js-button-delivery', function () {
        ReserveAndGo.switchDeliveryMethod(false);
    });
};

/**
 * On click continue reserve and go
 *
 */
ReserveAndGo.onClickContinue = () => {
    $(document).on('click', '.js-continue-rg', function () {
        ReserveAndGo.switchDeliveryMethod(true);
        $('#reserveAndGoModal').modal('hide');
    });
};

ReserveAndGo.getStoreStock = (googleHelperFunction) => {
    var $container = $('#modalStockStores');
    var options = {
        apothecary: true
    };

    googleHelper[googleHelperFunction]($container, options, (content) => {
        $('.js-stock-error').toggleClass('d-none', !content.storeStockServiceError);
        $('.js-store-results').html(content.pdpStoreResultsHtml);
    });
};

/**
 * On click search stores button
 *
 */
ReserveAndGo.onClickSearchStores = () => {
    $(document).on('submit', '.js-stock-stores-form', function (e) {
        e.preventDefault();
        ReserveAndGo.getStoreStock('searchStores');
        lastSelectedMethod = 'searchStores';
    });
};

/**
 * On click detect location button
 *
 */
ReserveAndGo.onClickDetectLocation = () => {
    $(document).on('click', '.js-detect-location', function () {
        ReserveAndGo.getStoreStock('detectStores');
        lastSelectedMethod = 'detectStores';
    });
};

/**
 * On click retry
 *
 */
ReserveAndGo.onClickRetryStoreModal = () => {
    $(document).on('click', '.js-retry-store-check', function (e) {
        e.preventDefault();
        ReserveAndGo.getStoreStock(lastSelectedMethod);
    });
};

/**
 * On click retry PDP
 *
 */
ReserveAndGo.onClickRetryPDP = () => {
    $(document).on('click', '.js-retry-pdp-store-check', function (e) {
        e.preventDefault();
        ReserveAndGo.setSelectedStorePDPInfo();
    });
};

/**
 * On click select favorite button
 *
 */
ReserveAndGo.onClickSelectFavorite = () => {
    $(document).on('click', '.js-make-favorite', function () {
        let $clickedElement = $(this);
        let setFavStoreUrl = $clickedElement.data('set-favorite-store-url');
        const getFavStoreUrl = $clickedElement.data('get-favorite-store-header-url');
        let urlParams = {
            storeID: $clickedElement.data('store-id')
        };
        setFavStoreUrl = appendToUrl(setFavStoreUrl, urlParams);

        $.ajax({
            url: setFavStoreUrl,
            type: 'post',
            success: function () {
                const $container = $clickedElement.parents('.js-store');
                $('.js-favorite-star').addClass('d-none');
                $('.js-make-favorite').removeClass('d-none');
                $container.find('.js-favorite-star').removeClass('d-none');
                $container.find('.js-make-favorite').addClass('d-none');

                $.ajax({
                    url: getFavStoreUrl,
                    type: 'get',
                    success: function (data) {
                        $('.header-top__favorite-store').html(data);
                    }
                });
            }
        });
    });
};

/**
 * On click select store
 *
 */
ReserveAndGo.onClickSelectStore = () => {
    $(document).on('change', '.js-set-store-selected', function () {
        if ($(this).is(':checked')) {
            const $container = $(this).parents('.js-store');
            const $confirmSelectedStoreModal = $('#confirmSelectedStoreModal');
            var storeId = $container.data('store-id');
            $confirmSelectedStoreModal.attr('store-id', storeId);
            $confirmSelectedStoreModal.modal({ backdrop: 'static', show: true });
            $('body').append($confirmSelectedStoreModal);
        }
    });

    $(document).on('show.bs.modal', '.modal', function () {
        // eslint-disable-next-line no-mixed-operators
        const zIndex = 1040 + 10 * $('.modal:visible').length;
        $(this).css('z-index', zIndex);
        setTimeout(() => $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack'));
    });
    $(document).on('click', '.modal .close', function (e) {
        e.preventDefault();
        $(this).closest('.modal').modal('hide');
    });
};

/**
 * On click confirm selected store
 *
 */
ReserveAndGo.onClickConfirmSelectedStore = () => {
    $(document).on('click', '.js-change-store-confirm', function () {
        const $confirmSelectedStoreModal = $('#confirmSelectedStoreModal');
        const selectedStoreId = $confirmSelectedStoreModal.attr('store-id');
        const $container = $(`.js-store[data-store-id="${selectedStoreId}"]`);
        let setSelectedStoreUrl = $confirmSelectedStoreModal.attr('data-set-selected-store-id-url');
        let setSelectedStoreUrlParams = {
            selectedStoreId: selectedStoreId
        };
        setSelectedStoreUrl = appendToUrl(
            setSelectedStoreUrl,
            setSelectedStoreUrlParams
        );

        $.ajax({
            url: setSelectedStoreUrl,
            type: 'post',
            success: function (res) {
                if (res.success) {
                    const $modalStockStores = $('#modalStockStores');
                    $modalStockStores.find('.js-add-to-cart-group').addClass('d-none');
                    $modalStockStores.find('.custom-checkbox').removeClass('d-none');
                    $modalStockStores.find('.js-set-store-selected').prop('checked', false);
                    $container.find('.custom-checkbox').addClass('d-none');
                    $('.js-store-results .js-selected-store-name').html(res.store.name);
                    ReserveAndGo.setSelectedStorePDPInfo(selectedStoreId);
                } else {
                    $container.find('.custom-checkbox').prop('checked', false);
                }
                $confirmSelectedStoreModal.modal('hide');
            },
            cache: false
        });
    });
};

/**
 * On click keep selected store
 *
 */
ReserveAndGo.onClickKeepSelectedStore = () => {
    $(document).on('click', '.js-change-store-keep', function () {
        const $confirmSelectedStoreModal = $('#confirmSelectedStoreModal');
        const selectedStoreId = $confirmSelectedStoreModal.attr('store-id');
        const $container = $(`.js-store[data-store-id="${selectedStoreId}"]`);
        $confirmSelectedStoreModal.modal('hide');
        $container.find('.js-set-store-selected').prop('checked', false);
    });
};

/**
 * AJAX call to set the selected store info on the PDP
 * @param {string} selectedStoreId - selectedStoreId
 *
 */
ReserveAndGo.setSelectedStorePDPInfo = (selectedStoreId) => {
    if (!$('.js-selected-store-pdp').length) return;
    if (selectedStoreId) {
        $('.js-selected-store-pdp').attr('data-selected-store-id', selectedStoreId);
    }
    // eslint-disable-next-line no-param-reassign
    selectedStoreId = $('.js-selected-store-pdp').attr('data-selected-store-id');
    if (!selectedStoreId) return;
    let $selectedStoreContainer = $('.js-selected-store-pdp');
    $selectedStoreContainer.spinner().start();
    let url = $('.js-selected-store-pdp').attr('data-get-selected-store');
    let urlParams = {
        selectedStoreId: selectedStoreId
    };
    url = appendToUrl(url, urlParams);

    $.ajax({
        url: url,
        type: 'get',
        success: function (res) {
            $selectedStoreContainer.html(res.html);
            $('.js-no-store-found').addClass('d-none');
            $('.js-store-found').removeClass('d-none');

            const $container = $(`.js-store[data-store-id="${selectedStoreId}"]`);
            if (res.selectedStore.available || (res.selectedStore.availability && res.selectedStore.availability.type === 'toBeConfirmed')) {
                $container.find('.js-add-to-cart-group').removeClass('d-none');
                $('.js-store-found').find('.js-add-to-cart-group').removeClass('d-none');
            } else if (!$selectedStoreContainer.find('.js-add-to-cart-group').hasClass('d-none')) {
                $('.js-store-found').find('.js-add-to-cart-group').addClass('d-none');
            }

            $selectedStoreContainer.spinner().stop();
        },
        error: function () {
            $selectedStoreContainer.spinner().stop();
        },
        cache: false
    });
};

/**
 * On cancel order
 *
 */
ReserveAndGo.cancelOrder = () => {
    $(document).on('click', '.js-cancel-order', function (e) {
        e.preventDefault();
        $.spinner().start();
        const $cancelButton = $(this);
        const $confirmCancelOrderModal = $('#confirmCancelOrderModal');
        var url = $cancelButton.attr('data-action');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                $.spinner().stop();
                if (data.success) {
                    notification.createNotification(data.message, 'success');
                    $('#cancelOrderModalButton').prop('disabled', true);
                } else {
                    notification.createNotification(data.message, 'danger');
                }
                $confirmCancelOrderModal.modal('hide');
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
};

ReserveAndGo.onClickShowMoreStores = () => {
    $(document).on('click', '.js-show-more-stores', function () {
        $('.js-store').removeClass('d-none');
        $('.js-show-more-stores').removeClass('d-block');
        $('.js-show-more-stores').addClass('d-none');
    });
};

/**
 * On hide modal
 *
 */
ReserveAndGo.onHidePrefferedStoreModal = () => {
    $('#modalPreferredStore').on('hidden.bs.modal', function () {
        const rgActive = $('.js-cart-container').attr('data-rgactive') === 'true';
        if ($('.page').data('action') === 'Cart-Show' && !rgActive) {
            location.reload();
        }
    });
};

/**
 * Init function
 *
 */
ReserveAndGo.init = () => {
    ReserveAndGo.onChangeHideRGPopup();
    ReserveAndGo.onClickReserveAndGo();
    ReserveAndGo.onConfirmDeliveryMethod();
    ReserveAndGo.onClickPDPTab();
    ReserveAndGo.onClickDelivery();
    ReserveAndGo.onClickContinue();
    ReserveAndGo.onClickSearchStores();
    ReserveAndGo.onClickDetectLocation();
    ReserveAndGo.onClickRetryStoreModal();
    ReserveAndGo.onClickRetryPDP();
    ReserveAndGo.onClickSelectFavorite();
    ReserveAndGo.onClickSelectStore();
    ReserveAndGo.onClickConfirmSelectedStore();
    ReserveAndGo.onClickKeepSelectedStore();
    ReserveAndGo.setSelectedStorePDPInfo();
    ReserveAndGo.cancelOrder();
    ReserveAndGo.onClickShowMoreStores();
    ReserveAndGo.onHidePrefferedStoreModal();
};

module.exports = ReserveAndGo;
